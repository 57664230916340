import { Box, Button, Heading, HStack, Spacer } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function SingleLessonSite() {
	const value = useContext(GlobalContext)
	const param = useParams()

	const rawHTML = `This is the html text running with dangerouslysetinnerhtml`

	return (
		<Box minH='100%' >
			<Box mb='5' shadow='base' >
				<ReactPlayer width='full' url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
			</Box>

			<Box mb='5' shadow='base' >
				<HStack p='2'>
					<Heading textAlign='center'>Kodok{param.lessonId}</Heading>
					<Spacer />
				</HStack>
			</Box>

			<Box mb='5' shadow='base' p='2'>
				<div
					dangerouslySetInnerHTML={{ __html: rawHTML }}
				/>
			</Box>

			<HStack pos='sticky' bottom='0' width='full'>
				<Button width='full' variant='outline' colorScheme={value.webConfig.colorScheme}>Mark as Finished</Button>
				<Button width='full' colorScheme={value.webConfig.colorScheme}>Next Lesson</Button>
			</HStack>
		</Box>
	)
}

export default SingleLessonSite
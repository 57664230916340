import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import CourseMicroSite from '../Pages/Course/CourseMicroSite'
import SingleCourseSite from '../Pages/Course/SingleCourseSite'
import SingleLessonSite from '../Pages/Course/SingleLessonSite'

function CourseRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><CourseMicroSite /></MicroFullLayout>} />
			<Route path="/:id" element={<MicroHeaderLayout>< SingleCourseSite /></MicroHeaderLayout>} />
			<Route path="/:id/lesson/:lessonId" element={<MicroHeaderLayout>< SingleLessonSite /></MicroHeaderLayout>} />

			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default CourseRoutes
import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	Stack,
	Text,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addArrayFirebase, loginUser } from '../../Api/firebaseApi'
import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function LoginPage() {
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const value = useContext(GlobalContext)


	const navigate = useNavigate()
	const handleLogin = async () => {
		console.log('handling login')
		setLoading(true)
		try {
			const result = await loginUser(data.email, data.password)
			console.log(result)
			if (result.status === 'success') {
				value.handleToast('Login Sukses', 'Anda telah berhasil login', 'success')
				await addArrayFirebase('users', auth.currentUser.uid, 'users', value.webConfig.projectsId)
				setLoading(false)
				navigate('/')
			}
			else {
				console.log(result)
				setLoading(false)
			}
		} catch (error) {
			console.log(error.message)
		}

	}

	const loginChecker = async () => {
		if (value.auth) {
			console.log(auth.currentUser.uid, 'login checker')
			navigate('/')
		}
	}

	useEffect(() => {
		loginChecker()
		return () => {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Container
			h='99vh'
			maxW="lg"
			py={{
				base: '12',
				md: '24',
			}}
			px={{
				base: '0',
				sm: '8',
			}}
		>
			<Stack spacing="8">
				<Stack spacing="6">
					<Stack
						spacing={{
							base: '2',
							md: '3',
						}}
						textAlign="center"
					>
						<Heading
							size={{
								base: 'xs',
								md: 'sm',
							}}
						>
							Log in to your account
						</Heading>
						<HStack spacing="1" justify="center">
							<Text color="muted">Don't have an account?</Text>
							<Button variant="link" colorScheme={value.webConfig.colorScheme}>
								Sign up
							</Button>
						</HStack>
					</Stack>
				</Stack>
				<Box
					py={{
						base: '0',
						sm: '8',
					}}
					px={{
						base: '4',
						sm: '10',
					}}
					bg={{
						base: 'transparent',
						sm: 'bg-surface',
					}}
					boxShadow={{
						base: 'none',
						sm: 'md',
					}}
					borderRadius={{
						base: 'none',
						sm: 'xl',
					}}
				>
					<Stack spacing="6">
						<Stack spacing="5">
							<FormControl>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input id="email" type="email" onChange={(e) => setData({ ...data, email: e.target.value })} />
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="password">Password</FormLabel>
								<Input id="password" type="password" onChange={(e) => setData({ ...data, password: e.target.value })} />
							</FormControl>
						</Stack>
						<HStack justify="space-between">
							<Checkbox defaultChecked>Remember me</Checkbox>
							<Button variant="link" colorScheme="blue" size="sm">
								Forgot password?
							</Button>
						</HStack>
						<Stack spacing="6">
							{loading ?
								<Button isLoading variant="primary">Sign in</Button>

								:
								<Button variant="primary"
									onClick={() => handleLogin()}
								>Sign in</Button>
							}
							<HStack>
								<Divider />
								<Text fontSize="sm" whiteSpace="nowrap" color="muted">
									or continue with
								</Text>
								<Divider />
							</HStack>
							{/* <OAuthButtonGroup /> */}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	)
}






export default LoginPage
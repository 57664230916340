import { Box, Button, HStack, Icon, Image, Input, Spacer, Stack, useColorMode } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FiBell, FiHeart, FiLogOut, FiMoon, FiShare2, FiSun } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { logOutUser } from '../../Api/firebaseApi'
// import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import BackButtonComponent from '../Buttons/BackButtonComponent'

function BasicHeaderComponent() {
	const value = useContext(GlobalContext)
	const { colorMode, toggleColorMode } = useColorMode()
	const location = useLocation()

	return (
		<Box position='sticky' pb='1' top='0' zIndex='100' width='full' bgColor={colorMode === 'light' ? 'white' : 'black'} >
			<HStack >
				<Link to='/'>
					<Image
						src={value.webConfig.logo}
						h='50px'
						alt='logo' />
				</Link>
				<Spacer />
				<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
					<Icon as={colorMode === 'light' ? FiMoon : FiSun} w='6' h='6' onClick={toggleColorMode} />
				</Stack>
				{value.auth ?
					<HStack p='2'>
						<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
							<Icon as={FiBell} w='6' h='6' />
						</Stack>
						<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
							<Icon as={FiHeart} w='6' h='6' />
						</Stack>
						<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
							<Icon as={FiLogOut} w='6' h='6' onClick={() => logOutUser()} />
						</Stack>
					</HStack>
					:
					<>
						<Link to='/authentication/signup'>
							<Button colorScheme={value.webConfig.colorScheme} variant='outline'>Signup</Button>
						</Link>
						<Link to='/authentication/login'>
							<Button mr='2' colorScheme={value.webConfig.colorScheme} >Login</Button>
						</Link>
					</>
				}
			</HStack>
			<HStack m='1'>
				{location.pathname !== '/' ? <BackButtonComponent /> : <></>}
				<Input type='text' placeholder='Search' />
				<Icon as={FiShare2} alignSelf='center' justifySelf='end' />
			</HStack>
		</Box>
	)
}

export default BasicHeaderComponent
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import LoginPage from '../Pages/Authentication/LoginPage'
import SigunUpPage from '../Pages/Authentication/SignUpPage'

function AuthenticationRoutes() {
	return (
		<MicroHeaderLayout>
			<Routes>
				<Route path="/login" element={< LoginPage />} />
				<Route path="/signup" element={< SigunUpPage />} />
				{/* <Route path="setting/*" element={<SettingRouter />} /> */}
				{/* <Route path="example/*" element={<ExampleRouter />} /> */}
			</Routes>
		</MicroHeaderLayout>
	)
}

export default AuthenticationRoutes

import { useContext} from 'react';
import { ChakraProvider, extendTheme, ScaleFade, Stack, Text} from '@chakra-ui/react';
import MainRoutes from './Routes/MainRoutes';
import LoaderComponent from './Components/Spinners/LoaderComponent';
import { GlobalContext } from './Hooks/Contexts/GlobalContext';
import Error404 from './Pages/Error/Error404';

function App() {
	const value = useContext(GlobalContext)
	console.log(value)
	  
  return (
	<ChakraProvider theme={extendTheme( {colors:{brand: value?.webConfig?.brand?value.webConfig.brand : 0}} )}>
	  {value.loading?
		<ScaleFade initialScale={36} in>
		<Stack alignItems='center' minH='99vh' justifyContent='center' >
						<LoaderComponent/>
						<Text>Build & design by deoapp.com</Text>
		</Stack>
		</ScaleFade>
		:
		value?.webConfig?.brand?
			<MainRoutes/>
			:
			<Error404/>
		}
		</ChakraProvider>
  );
}

export default App;

import { Container } from '@chakra-ui/react'
import React from 'react'
import BasicHeaderComponent from '../Components/Header/BasicHeaderComponent'
import MicroNavbar from '../Components/Navbar/MicroNavbar'

function MicroFullLayout({ children }) {
	return (
		<Container shadow='base' maxW='md' p='0' minH='99vh'>
			<BasicHeaderComponent />
			{children}
			<MicroNavbar />
		</Container>


	)
}

export default MicroFullLayout
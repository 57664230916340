import { Box, Heading, HStack, Icon, Spacer, Text, Image, Button, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';

function ListCardComponent(props) {
	const value = useContext(GlobalContext)
	const x = props.data
	return (
		<Link to={`${x.id}`}>
			<HStack p='2' m='2' border='1px' borderRadius='md'>
				<Image objectFit="cover" w='200px' h='200px' src='https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' alt='kodok' />
				<VStack>
					<HStack>
						<Box>
							<Heading fontSize='md'>{x.title}</Heading>
							<Text fontSize='xs'>{x.description}</Text>
						</Box>
						<Spacer />
						<Text textAlign='end'>${x.price}</Text>
						<Icon as={FiArrowRight} />
					</HStack>
					<Spacer />
					<Button justifySelf='flex-end' w='full' colorScheme={value.webConfig.colorScheme}>View</Button>
				</VStack>
			</HStack>
		</Link>
	)
}

export default ListCardComponent
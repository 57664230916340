import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import CartMicroSite from '../Pages/Cart/CartMicroSite'

function CartRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroHeaderLayout><CartMicroSite /></MicroHeaderLayout>} />
			{/* <Route path="/:id" element={<MicroHeaderLayout>< SingleCourseSite /></MicroHeaderLayout>} />
			<Route path="/:id/lesson/:lessonId" element={<MicroHeaderLayout>< SingleLessonSite /></MicroHeaderLayout>} /> */}

			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default CartRoutes
import { Box, Heading, HStack, Icon, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { FiArrowRight, FiDollarSign, FiUser } from 'react-icons/fi'
import { Link } from 'react-router-dom'

function AccountMicro() {
	const data = [
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiDollarSign, title: 'payment setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
		{ icon: FiUser, title: 'account setting', link: 'account' },
	]

	return (
		<Box h='100%'>
			<Heading p='2'>Setting</Heading>
			{data.map((x, i) =>
				<Link key={i} to={x.link}>
					<HStack borderBottom='1px' m='2'>
						<Icon as={x.icon} />
						<Text>{x.title}</Text>
						<Spacer />
						<FiArrowRight />
					</HStack>
				</Link>
			)}

		</Box>
	)
}

export default AccountMicro
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import AccountMicro from '../Pages/Settings/AccountMicro'

function SettingRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><AccountMicro /></MicroFullLayout>} />
			<Route path="/account" element={<MicroHeaderLayout>< AccountMicro /></MicroHeaderLayout>} />
			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default SettingRoutes
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Error404 from '../Pages/Error/Error404'
import HomeMicroSite from '../Pages/Home/HomeMicroSite'
import AuthenticationRoutes from './AuthenticationRoutes'
import CartRoutes from './CartRoutes'
import CourseRoutes from './CourseRoutes'
import NewsRoutes from './NewsRoutes'
import { RoutesAnimation } from './RoutesAnimation'
import SettingRoutes from './SettingRoutes'

function MainRoutes() {
	return (
		<Routes>
			<Route element={<RoutesAnimation />}>
				<Route path="/" element={<HomeMicroSite />} />
				<Route path="course/*" element={<CourseRoutes />} />
				<Route path="news/*" element={<NewsRoutes />} />
				<Route path="authentication/*" element={<AuthenticationRoutes />} />
				<Route path="cart/*" element={<CartRoutes />} />
				<Route path="setting/*" element={<SettingRoutes />} />
				<Route path="*" element={<Error404 />} />
			</Route>

			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default MainRoutes
import { Container, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import HomeCarousel from '../../Components/Carousel/HomeCarousel'
import { CarouselComponent } from '../../Components/Galleries/Carousel/CarouselComponent'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import MicroFullLayout from '../../Layouts/MicroFullLayout'

function HomeMicroSite() {
	const value = useContext(GlobalContext)

	// console.log(webConfig)
	return (
		<>
			<MicroFullLayout>
				<CarouselComponent images={value.webConfig.banner} />
				{value.webConfig.feature.map((x, i) =>
					<Container key={i} maxWidth='2xl' mb='3' p='2'>
						<HStack >
							<Heading fontSize='lg'>{x}</Heading>
							<Spacer />
							<Link to={`/${x}`}>
								<Text fontSize='xs'>See More...</Text>
							</Link>
						</HStack>
						<Flex width='full'  >
							<HomeCarousel />
						</Flex>
					</Container>
				)}
			</MicroFullLayout>
		</>
	)
}

export default HomeMicroSite
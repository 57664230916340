import { Container } from '@chakra-ui/react'
import React from 'react'
import BasicHeaderComponent from '../Components/Header/BasicHeaderComponent'

function MicroHeaderLayout({ children }) {
	console.log('in header only layout')
	return (
		<Container shadow='base' maxW='md' p='0' minH='100vh'>
			<BasicHeaderComponent />
			{children}
		</Container>
	)
}

export default MicroHeaderLayout
import { Box, Button, Image, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function HomeCarousel() {
	const value = useContext(GlobalContext)
	// const data = props.data

	const data = [
		{ title: 'product #1', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' },
		{ title: 'product #2', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' },
		{ title: 'product #3', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' },
		// { title: 'product #4', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' },
		// { title: 'product #5', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80' },
	]
	// const getData = async () => {


	// }


	// useEffect(() => {
	// 	getData()
	// 	return () => {
	// 	}
	// }, [])

	return (
		data.map((x, i) =>
			<Box key={i} shadow='base' p='2' m='1'>
				<Image objectFit="cover" src={x.image} alt={x.title} />
				<Text>{x.title}</Text>
				<Button width='full' size='xs' colorScheme={value.webConfig.colorScheme}>Lihat</Button>
			</Box>
		)
	)
}

export default HomeCarousel
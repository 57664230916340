import { Button, Heading, HStack, Icon, Spacer, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FiPackage } from 'react-icons/fi'
import { CartItem } from '../../Components/Carts/CartDrawer/CartItem'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function CartMicroSite() {
	const value = useContext(GlobalContext)
	const cartData = [
		{
			id: '1',
			price: 39.99,
			currency: 'GBP',
			name: 'Ferragamo bag',
			description: 'Tan, 40mm',
			quantity: 3,
			imageUrl:
				'https://images.unsplash.com/photo-1584917865442-de89df76afd3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80',
		},
		{
			id: '2',
			price: 39.99,
			currency: 'GBP',
			name: 'Bamboo Tan',
			description: 'Tan, 40mm',
			quantity: 3,
			imageUrl:
				'https://images.unsplash.com/photo-1591561954557-26941169b49e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80',
		},
		{
			id: '3',
			price: 39.99,
			currency: 'GBP',
			name: 'Yeezy Sneakers',
			description: 'Tan, 40mm',
			quantity: 3,
			imageUrl:
				'https://images.unsplash.com/photo-1604671801908-6f0c6a092c05?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80',
		},
	]

	const handleCheckout = async () => {

	}

	return (<>
		{/* <Box> */}
		<Stack
			height="full"
			spacing="8"
			overflowY="auto"
		>
			<HStack p='2' bgColor='brand.1'>
				<Heading size="md">Shopping Cart</Heading>
				<Spacer />
				<Heading size="md">({cartData.length} items)</Heading>
			</HStack>
			<Stack
				spacing={{
					base: '8',
					md: '10',
				}}
				p='2'
			>
				{cartData.map((item) => (
					<CartItem key={item.id} {...item} />
				))}
			</Stack>
		</Stack>
		<Stack
			borderTopWidth="1px"
			px={{
				base: '6',
				md: '10',
			}}
			py="4"
			spacing="5"
		>
			<Stack>
				<HStack fontSize="xl" fontWeight="semibold">
					<Text flex="1">Subtotal:</Text>
					<Text>£597.00</Text>
				</HStack>
				<HStack spacing="2" color={useColorModeValue('gray.600', 'gray.400')}>
					<Icon as={FiPackage} />
					<Text>Shipping + taxes calculated at checkout</Text>
				</HStack>
			</Stack>
			<Button pos='sticky' onClick={() => handleCheckout()} bottom='0' colorScheme={value.webConfig.colorScheme} size="lg" fontSize="md">
				Checkout
			</Button>
		</Stack>
		{/* </Box> */}
	</>
	)
}

export default CartMicroSite